(function($) {
  "use strict"; // Start of use strict

  function bindOpenReview() {
    $('.open_new_review').on('click', function(){
      $(this).hide()
      $('.new_review').show()
    }); 
  }
  setTimeout(bindOpenReview, 0);
  $(document).on('turbolinks:load', function() { bindOpenReview() } )

  function bindOpenAdvancePayment() {
    $('.open_new_advance-payment').on('click', function(){
      $(this).hide()
      $('.new_advance_payment').show()
    });
  }
  setTimeout(bindOpenAdvancePayment, 0);
  $(document).on('turbolinks:load', function() { bindOpenAdvancePayment() } )

  function bindOpenBonusPayment() {
    $('.open_new_bonus-payment').on('click', function () {
      $(this).hide();
      $('.new_bonus_payment').show();
    });
  }
  setTimeout(bindOpenBonusPayment, 0);
  $(document).on('turbolinks:load', function () { bindOpenBonusPayment() });

  // Toggle the side navigation
  $("#sidebarToggle").on('click',function(e) {
    e.preventDefault();
    $("body").toggleClass("sidebar-toggled");
    $(".sidebar").toggleClass("toggled");
  });

  // Prevent the content wrapper from scrolling when the fixed side navigation hovered over
  $('body.fixed-nav .sidebar').on('mousewheel DOMMouseScroll wheel', function(e) {
    if ($(window).width() > 768) {
      var e0 = e.originalEvent,
        delta = e0.wheelDelta || -e0.detail;
      this.scrollTop += (delta < 0 ? 1 : -1) * 30;
      e.preventDefault();
    }
  });

  // Scroll to top button appear
  $(document).on('scroll',function() {
    var scrollDistance = $(this).scrollTop();
    if (scrollDistance > 100) {
      $('.scroll-to-top').fadeIn();
    } else {
      $('.scroll-to-top').fadeOut();
    }
  });
})(jQuery); // End of use strict
