function initCurrency() {
  if ( $('.select-currency').length ) {
    let currency = $('.select-currency').val().toLowerCase();

    $(".usd-block, .eur-block, .uah-block").hide();
    $(`.${currency}-block`).show();
    $(".select-currency").on("change", initCurrency);
  }
}

$(document).on("turbolinks:load", initCurrency);
