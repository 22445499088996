function updateProjectLabel(e, insertedItem) {
  let projectCount = $(".nested-fields.project-field").length;
  insertedItem.find('label.project-label').text(`Project ${projectCount}`);
}
function updateProjectLink() {
  const baseLink = 'https://mine.everlabs.com/projects/';
  const projectLink = $(this).closest('.redmine-project-slug').find('a')[0];
  projectLink.href = `${baseLink}${this.value}`;
}

let timeoutId;
function checkProjectSlug() {
  const input = $(this);
  const $loader = $(this).siblings('#loader');
  let redmineUserId, redmineProjectSlug;

  if (input.hasClass('redmine-user-id'))
    redmineUserId = input.val();
  else
    redmineProjectSlug = input.val();

  clearTimeout(timeoutId);
  $loader.show();

  timeoutId = setTimeout(function() {
    $.ajax({
      url: '/clients/check_redmine_url',
      dataType: 'json',
      data: {
        redmine_project_slug: redmineProjectSlug,
        redmine_user_id: redmineUserId
      },
      success: function(data) {
        input.removeClass('is-invalid').addClass('is-valid');
        if (data.hasOwnProperty('user')) {
          let userNameField = input.closest('.project-role').find('.redmine-user-name')
          let fullName = data.user.firstname + ' ' + data.user.lastname;
          userNameField.html(fullName);
        }
      },
      error: function() {
        input.removeClass('is-valid').addClass('is-invalid');
      }
    });
    $loader.hide();
  }, 1500);
}

function checkInclusionFields() {
  let inclusionPercent = $('#inclusion-percent');

  if ($('#inclusion-name').val().trim() !== '') {
    inclusionPercent.attr('required', 'required');
  } else {
    inclusionPercent.removeAttr('required');
  }
}

function toggleDescriptionFields() {
  $('#ua-entity-description').toggleClass('d-none');
}

$(document).on('keyup', '.slug', updateProjectLink);
$(document).on('change', '.slug, .redmine-user-id', checkProjectSlug);
$(document).on('cocoon:after-insert', '#projects', updateProjectLabel);
$(document).on('input', '#inclusion-name', checkInclusionFields);
$(document).on('change', '#ua-entity-checkbox', toggleDescriptionFields);
