$(document).on("turbolinks:load", function() {
  let formSubmitting = false;
  let formChanged = false;

  function setFormSubmitting() {
    formSubmitting = true;
  }

  const warningForms = $('form[data-warning="true"]');

  if (warningForms.length > 0) {
    $("a").each(function () {
      $(this).attr("data-turbolinks", "false");
    });

    warningForms.find('input, textarea, select').on('change input', function() {
      formChanged = true;
    });

    function beforeLeave(e) {
      if (formSubmitting || !formChanged) {
        return undefined;
      }

      if (!e) e = window.event;

      e.cancelBubble = true;
      e.returnValue = 'If you leave before saving, your changes will be lost.';

      if (e.stopPropagation) {
        e.stopPropagation();
        e.preventDefault();
      }
    }

    window.onbeforeunload = beforeLeave;

    warningForms.on("submit", function() {
      setFormSubmitting();
    });

    $("#postponed-link").click(function(e){
      formSubmitting = true;
    });
  }
});
