function recalculateTotal() {
  if ( $("#invoice_price").length ) {
    let total = 0;

    $('.nested-fields').not(':hidden').each(function() {
      let quantity = parseFloat($(this).find('.item-quantity').val());
      let price    = parseFloat($(this).find('.item-price').val());

      $(this).find('.item-amount').val((quantity * price).toFixed(2));
      total = total + (quantity * price);
    });

    $('#invoice_price').val((total).toFixed(2));
  }
}
function changeCurrencyHint() {
  $('.currency-hint').text($('.select-currency').val());
}

$(document).on('change', '.select-currency', changeCurrencyHint);
$(document).on("change", ".item-quantity", recalculateTotal);
$(document).on("keyup", ".item-price, .item-quantity", recalculateTotal);
$(document).on("cocoon:after-insert cocoon:after-remove", "#invoice_items", recalculateTotal);
$(document).on('turbolinks:load', recalculateTotal);



