// This file is automatically compiled by Webpack, along with any other files
// present in this directory. You're encouraged to place your actual application logic in
// a relevant structure within app/javascript and only use these pack files to reference
// that code so it'll be compiled.

require("@rails/ujs").start();
require("turbolinks").start();
require("@rails/activestorage").start();
require("trix");
require("@rails/actiontext");
require("channels");

const images = require.context('../src/images', true)
const imagePath = (name) => images(name, true)
window.imagePath = imagePath

// App Stylesheets
import 'stylesheets/application'

import echarts from 'echarts/lib/echarts';
import 'echarts/lib/chart/pie';
import 'echarts/lib/chart/bar';
import 'echarts/lib/chart/line';
import 'echarts/lib/component/tooltip';
import 'echarts/lib/component/title';
import 'echarts/lib/component/legend';
import 'echarts/lib/component/grid';

window.echarts = echarts;

// jQuery
import $ from "jquery"
window.$ = $
window.jQuery = $

// Bootstrap
import 'bootstrap'

// Toast notifications
import toastr from 'toastr'
window.toastr = toastr
toastr.options = {
  "closeButton": true,
  "debug": false,
  "newestOnTop": false,
  "progressBar": true,
  "positionClass": "toast-bottom-right",
  "preventDuplicates": false,
  "onclick": null,
  "showDuration": "300",
  "hideDuration": "1000",
  "timeOut": "10000",
  "extendedTimeOut": "1000",
  "showEasing": "swing",
  "hideEasing": "linear",
  "showMethod": "fadeIn",
  "hideMethod": "fadeOut"
}

// Moment JS
import * as moment from 'moment'
window.moment = moment

// Bootstrap DateTime Picker
import 'bootstrap4-datetimepicker'
$.extend(true, $.fn.datetimepicker.defaults, {
  locale: 'en',
  icons: {
    time: 'far fa-clock',
    date: 'far fa-calendar',
    up: 'fas fa-arrow-up',
    down: 'fas fa-arrow-down',
    previous: 'fas fa-chevron-left',
    next: 'fas fa-chevron-right',
    today: 'fas fa-calendar-check',
    clear: 'far fa-trash-alt',
    close: 'far fa-times-circle'
  }
})

// App Javascripts
import 'javascripts/users'
import 'javascripts/contracts/form'
import 'javascripts/canvas_init'
import 'javascripts/calculate_money'
import 'javascripts/sb-admin/sb-admin'
import 'javascripts/cocoon'
import 'javascripts/select_user'
import 'javascripts/batch'
import 'javascripts/check_invoices'
import 'javascripts/hide_currency'
import 'javascripts/select'
import 'javascripts/reviews'
import 'javascripts/app'
import 'javascripts/batch_pay'
import 'javascripts/clients'
import 'javascripts/rates'
import 'javascripts/client_invoices'
import 'javascripts/change_year'
import 'javascripts/warning_before_leave'
import 'javascripts/finances'
import 'javascripts/advance_payments'
import 'javascripts/charts'
