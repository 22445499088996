window.initContactForm = function initContactForm() {
  $(".datepicker").datetimepicker({
    format: 'DD.MM.YYYY'
  });

  fillContractFields();
}

function fillContractFields() {
  $("#contract_user_id").on("change", function() {
    let selectedUser      = $(this).find("option:selected");
    let name              = selectedUser.text().split(' ');
    let initials          = name[0][0] + name[1];
    let contractNumber    = `${moment().format("DDMMYYYY")}-Everlabs-${initials}`;
    let userCredentials   = selectedUser.data("credentials");
    let userCredentialsUk = selectedUser.data("credentials-uk");
    let userFullname      = `${name[0]} ${name[1]}`;
    let userFullnameUk    = userCredentialsUk.split(',')[0];

    $("#contract_number").val(contractNumber);
    $("#contract_contractor_credentials").val(userCredentials);
    $("#contract_contractor_credentials_uk").val(userCredentialsUk);
    $("#contract_contractor_name").val(userFullname);
    $("#contract_contractor_name_uk").val(userFullnameUk);
  });
}

$(document).on("turbolinks:load", initContactForm);
