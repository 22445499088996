if ("undefined" == typeof jQuery) throw new Error("select_all requires jQuery");
! function(t) {
  t.fn.select_all = function(e) {
    function a(e) {
      for (var a = [], c = []; !e.is("body");)(c = e.find(":checkbox.selectable")).length > 0 ? (a = c, e = t("body")) : e = e.parent();
      return a
    }

    function c(e, a, c) {
      switch (e.data("show_count")) {
        case "checked":
          count_result = "(Selected " + a + ")";
          break;
        case "checked_with_total":
          count_result = "(" + a + "/" + c + ")";
          break;
        case "unchecked":
          var l = c - a;
          count_result = "(Unselected " + l + ")";
          break;
        default:
          count_result = "(Selected " + a + ")"
      }
      var n = t(e.data("attach_count_to"));
      0 == n.length && (n = e.parent()), t("span#select_all_count_id[data-select_all_uid='" + e.data("select_all_uid") + "']").length > 0 ? t("span#select_all_count_id[data-select_all_uid='" + e.data("select_all_uid") + "']").text(count_result) : t("<span id='select_all_count_id' data-select_all_uid=" + e.data("select_all_uid") + ">" + count_result + "</span>").appendTo(n)
    }

    function l(e) {
      t(e).change(function() {
        var e = t(".select_all[data-select_all_class='" + s.class + "']"),
          a = t(".selectable." + s.class + ":checked").length,
          l = t(".selectable." + s.class).length;
        a == l ? e.prop("checked", "checked") : e.prop("checked", !1), e.data("show_count").length > 0 && c(e, a, l)
      })
    }
    var n, s = t.extend({
      class: "no_class",
      infinite_scroll_select: !1,
      show_count: !1,
      attach_count_to: !1,
      uniq_id: Math.random()
    }, e);
    if (0 == t(this).length) return null;
    var d = t(this),
      o = d;
    return d.addClass("select_all").attr("data-select_all_class", s.class).attr("data-select_all_uid", s.uniq_id), d.data("show_count", s.show_count), d.data("attach_count_to", s.attach_count_to), (n = a(o)).length > 0 && n.addClass(s.class), t(".select_all[data-select_all_class='" + s.class + "']").change(function() {
      t(".selectable." + s.class).prop("checked", t(this).prop("checked"))
    }), s.infinite_scroll_select && (t(d).attr("data-total_child_count", n.length), t(document).ajaxComplete(function(e, n, s) {
      t("[data-total_child_count]").each(function() {
        var e = t(this),
          n = a(e);
        n.length > e.data("total_child_count") && (n.addClass(e.data("select_all_class")), e.attr("data-total_child_count", n.length), l(n), e.prop("checked") && n.prop("checked", !0), e.data("show_count") && c(e, n.parent().find(":checked").length, n.length))
      })
    })), !1 !== s.show_count && c(d, n.parent().find(":checked").length, n.length),
      function(e) {
        e.change(function() {
          var a = t(".selectable." + s.class),
            l = t(this).prop("checked");
          if (a.prop("checked", l), e.data("show_count").length > 0) {
            var n = l ? a.length : 0;
            c(e, n, a.length)
          }
        })
      }(d), l(n), t(this)
  }
}(jQuery);