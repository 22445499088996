function setClient() {
  $(location).attr("href", this.value);
}

function updateStatus() {
  let form = $(this).closest('form'),
    status = $(this).val(),
    actionUrl = form.attr('action'),
    previousValue = $(this).data('val');

  if ($(this).attr('id') === 'client_invoice_status') {
    actionUrl += '?updated_from=month_cell';
  }

  if (status === 'paid' || status === 'partial') {
    let invoiceId = form.data('invoice-id');
    $('#paid-date-modal-' + invoiceId).modal('show');

    if (status === 'partial') {
      $(this).val(previousValue);
    }
  } else {
    $.post(actionUrl, form.serialize(), null, 'script');
  }
}

function updateDescription() {
  const baseDescription = "Software development services";
  const fromDate = formatDate($('#invoice-from').val());
  const toDate = formatDate($('#invoice-to').val());
  const $descriptionField = $('#invoice-description');
  let newDescription = `${baseDescription} in ${fromDate}`;

  if (fromDate !== toDate) {
    newDescription += ` - ${toDate}`;
  }
  $descriptionField.val(newDescription);
}

function formatDate(date) {
  const options = { month: 'long', year: 'numeric' };
  return new Date(date).toLocaleString('en-US', options);
}

function updateInvoiceItems() {
  const formType = $('form[data-client-id]').data('form-type');
  if (formType !== 'new' && formType !== 'create') return;

  const currentUrl = updateUrlWithDateRange();
  const $clientInvoiceItemsFields = $('#client-invoice-items');
  const $loader = $('#loader');

  $clientInvoiceItemsFields.hide();
  $loader.show();

  $.get(currentUrl.toString(), function (data) {
    $clientInvoiceItemsFields.replaceWith($(data).find("#client-invoice-items"));
    displayFlashErrorMessage(data);

    $loader.hide();
    $clientInvoiceItemsFields.show();
    calculateSum();
  });
}

function updateUrlWithDateRange() {
  const clientId = $('form[data-client-id]').data('client-id');
  const baseUrl = `${window.location.origin}/clients/${clientId}/client_invoices/new`;
  const currentUrl = new URL(baseUrl);
  currentUrl.searchParams.set("from", $('#invoice-from').val());
  currentUrl.searchParams.set("to", $('#invoice-to').val());
  generateRedmineReportUrl();
  return currentUrl;
}

function displayFlashErrorMessage(data) {
  const errorMessage = $(data).find("#flash-error-message").text();
  toastr.remove();
  if ( errorMessage.length) {
    toastr.error(errorMessage, 'Info');
  }
}

function recalculateInvoiceItems(prevCurrency, currentCurrency) {
  $('.item-rate').each(function () {
    convert($(this), prevCurrency, currentCurrency);
  });
}

function convert($this, prevCurrency, currentCurrency) {
  const privateRates = $('.bank-rates').data('private-rates');
  const $rateInput = $this;
  const rate = $rateInput.val();
  const $quantityInput = $rateInput.closest('.nested-fields').find('.item-quantity');
  const quantity = $quantityInput.val();

  const rates = {
    UAH: { EUR: privateRates['EUR']['buy'], USD: privateRates['USD']['buy'] },
    USD: { EUR: privateRates['EUR']['buy'] / privateRates['USD']['buy'], UAH: 1 / privateRates['USD']['buy'] },
    EUR: { USD: privateRates['USD']['buy'] / privateRates['EUR']['buy'], UAH: 1 / privateRates['EUR']['buy'] },
  };

  if (rates[currentCurrency] && rates[currentCurrency][prevCurrency]) {
    const conversionRate = rates[currentCurrency][prevCurrency];
    if ($rateInput.is(':visible')) {
      const convertedRate = (rate * conversionRate).toFixed(2);
      $rateInput.val(convertedRate);
      const $amountInput = $rateInput.closest('.nested-fields').find('.item-amount');
      $amountInput.val((convertedRate * quantity).toFixed(2));
    } else {
      const $amountInput = $rateInput.closest('.nested-fields').find('.item-amount');
      const currentAmount = parseFloat($amountInput.val());
      if (!isNaN(currentAmount)) {
        const convertedAmount = (currentAmount * conversionRate).toFixed(2);
        $amountInput.val(convertedAmount);
      }
    }
  }
  calculateSum();
}

$(document).on('turbolinks:load', function () {
  const $currencySelect = $('select#client_invoice_currency');
  let prevCurrency = $currencySelect.val();

  $currencySelect.on('change', function () {
    const currentCurrency = $(this).val();
    recalculateInvoiceItems(prevCurrency, currentCurrency);
    prevCurrency = currentCurrency;
  });
  calculateSum();
  generateRedmineReportUrl();
});

function updateAmount() {
  const $row = $(this).closest('.nested-fields');
  const $rateField = $row.find('.item-rate');
  const $quantityField = $row.find('.item-quantity');
  const $amountField = $row.find('.item-amount');
  const rate = parseFloat($rateField.val());
  const quantity = parseFloat($quantityField.val());
  const newAmount = (rate * quantity).toFixed(2);
  $amountField.val(newAmount);
  calculateInclusionRate();
  calculateSum();
}

function calculateSum() {
  let sum = 0;
  $('.item-amount:visible').each(function() {
    let value = parseFloat($(this).val()) || 0;
    sum += value;
  });
  $('#total-sum').text(sum.toFixed(2));
}

function submitPostponedInvoice(e) {
  e.preventDefault();
  $.ajax({
    type: "POST",
    url: $(this).attr('href'),
    data: {
      client_invoice: {
        notes: $('#client-notes-field').val()
      }
    }
  });
}

const generateRedmineReportUrl = () => {
  let projectIdsQueryParams = '';
  $('.client-invoice-item').each(function() {
    if ($(this).attr('data-redmine-project-id').length > 0) {
      projectIdsQueryParams += `v%5Bproject_id%5D%5B%5D=${$(this).attr('data-redmine-project-id')}&`;
    }
  });
  const url = `https://mine.everlabs.com/time_entries/report?utf8=%E2%9C%93&criteria%5B%5D=user&set_filter=1&
sort=spent_on%3Adesc&f%5B%5D=spent_on&op%5Bspent_on%5D=%3E%3C&v%5Bspent_on%5D%5B%5D=${$('#invoice-from').val()}
&v%5Bspent_on%5D%5B%5D=${$('#invoice-to').val()}&f%5B%5D=project_id&op%5Bproject_id%5D=%3D
&${projectIdsQueryParams}f%5B%5D=&c%5B%5D=project&c%5B%5D=spent_on&c%5B%5D=user&c%5B%5D=activity&c%5B%5D=issue
&c%5B%5D=comments&c%5B%5D=hours&group_by=&t%5B%5D=hours&t%5B%5D=&columns=month&criteria%5B%5D=&encoding=ISO-8859-1`;
  $('#redmine-report-link').attr('href', url);
}

function calculateInclusionRate () {
  let sum = 0;
  const $inclusionRateInput = $('.inclusion-rate');
  const inclusionRate = $inclusionRateInput.data('inclusion-rate');
  $('.item-amount').not('.inclusion-rate').not('.custom-client-invoice-item').each(function () {
    const value = parseFloat($(this).val()) || 0;
    if (value >= 0) {
      sum += value;
    }
  });
  $inclusionRateInput.val((sum * parseInt(inclusionRate) / 100).toFixed(2));
}

function fetchUnregisteredProjects() {
  $('#loader').show();
  $.ajax({
    url: "/client_invoices/unregistered_projects",
    type: "GET",
    dataType: "script",
  });
}

function calculateRemainingPayment() {
  let amount    = $(this).data('amount'),
    modalBody = $(this).closest('.modal-body'),
    totalAmount = 0;

  modalBody.find('input.amount:visible').each(function() {
    let inputValue = parseFloat($(this).val()) || 0;
    totalAmount += inputValue;
  });

  let newAmount = amount - totalAmount;
  if (newAmount < 0) newAmount = 0;

  let lastInput = modalBody.find('input.amount:visible').last();

  lastInput.val(newAmount.toFixed(2));
}

$(document).on('change', '#client-select', setClient);
$(document).on('focusin', '#status-select, select.invoice-status-select', function() {
  $(this).data('val', $(this).val());
});
$(document).on('change', '#status-select, select.invoice-status-select', updateStatus);
$(document).on('change', '#invoice-to, #invoice-from', function() {
  updateDescription();
  updateInvoiceItems();
});
$(document).on('change', '.item-rate, .item-quantity', updateAmount);
$(document).on('change', '.item-amount', calculateSum);
$(document).on('click', '#postponed-link', submitPostponedInvoice);
$(document).on('cocoon:after-insert', function(e, newItem) {
  $(newItem).find('.item-amount').addClass('custom-client-invoice-item');
});
$(document).on('cocoon:after-remove', function() {
  calculateSum();
});

$( document ).on('turbolinks:load', function() {
  $('#unregistered-projects').on('show.bs.collapse', function () {
    fetchUnregisteredProjects();
  });
});

$(document).on('click', '.add-payment', calculateRemainingPayment);
