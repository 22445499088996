$(document).on('turbolinks:load', function () {
  const privateRates = $('.new-batch').data('private-rates');
  calculateAmount();
  showTotals();
  showAdvanceTotals();
  showBonusTotals();
  $('#batch-currency').on('change', function () {
    console.log('here')
    let batchCurrency = $(this).val();
    if (batchCurrency === "Not selected") return
    $('.amount-counter').each(function () {
      var prev = $(this).val();
      $(this).data('val', prev)
      $(this).val(batchCurrency)
      var $this = $(this)
      convert($this)
      showTotals()
    })
  });
  $(".focus_on_me").click(function (e) {
    e.stopPropagation()
    e.preventDefault()
  })

  $(".remove-user").click(function (e) {
    e.preventDefault();
    let id = $(this).attr("id");

    $(`#${id}`).parents('tr').remove();
    buttonStatus();
  });

  function calculateAmount() {
    if (!privateRates) return
    var rows = $('#dataTable tr')
    rows.each(function () {
      var $row = $(this)
      var price = parseFloat($row.find('.amount').val())
      var currency = $row.find('.batch-value').val()

      var eur = parseFloat(privateRates['EUR']['buy'])
      var usd = parseFloat(privateRates['USD']['buy'])

      if (currency === 'UAH') $row.find('.amount').val((price * usd).toFixed(2))
      if (currency === 'EUR') $row.find('.amount').val((price / eur * usd).toFixed(2))
    })
  }

  $(".amount-counter").on('focusin', function () {
    var prev = $(this).val();
    $(this).data('val', prev)
  })

  function convert($this) {
    var prev = $this.data('val');
    var $amountInput = $this.parent().parent().find('.amount-field')
    var $focusOnMe = $this.parent().parent().find('.focus_on_me')
    var price = $amountInput.val()
    var currency = $this.val()

    var eur = parseFloat(privateRates['EUR']['buy'])
    var usd = parseFloat(privateRates['USD']['buy'])
    var uah = 1.0

    if (currency === 'UAH' && prev === 'USD') $amountInput.val((price / uah * usd).toFixed(2))
    if (currency === 'UAH' && prev === 'EUR') $amountInput.val((price / uah * eur).toFixed(2))
    if (currency === 'USD' && prev === 'UAH') $amountInput.val((price / usd * uah).toFixed(2))
    if (currency === 'USD' && prev === 'EUR') $amountInput.val((price / usd * eur).toFixed(2))
    if (currency === 'EUR' && prev === 'UAH') $amountInput.val((price / eur * uah).toFixed(2))
    if (currency === 'EUR' && prev === 'USD') $amountInput.val((price / eur * usd).toFixed(2))
    $focusOnMe.focus()
  }

  function showTotals() {
    var rows = $('#dataTable tr')
    var usd = 0
    var uah = 0
    var eur = 0
    rows.each(function () {
      var $row = $(this)
      var price = parseFloat($row.find('.amount').val())
      var currency = $row.find('.batch-value').val()

      if (currency === 'UAH') uah += price
      if (currency === 'EUR') eur += price
      if (currency === 'USD') usd += price
    })
    $('#amount_counter').val(`Total: ${uah.toFixed(2)} UAH, ${eur.toFixed(2)} EUR, ${usd.toFixed(2)} USD`)
  }

  $('.amount-counter').change(function () {
    var $this = $(this)
    convert($this)
    showTotals()
  });

  $('.amount-field').change(function () {
    showTotals()
  });

});

function buttonStatus() {
  let trCount = $('#dataTable').find('tr').length;

  if (trCount === 1) $('#generate-button').attr("disabled", true);
}

function showAdvanceTotals() {
  var n = 0
  $(".advance-amount").each(function () {
    n += Number($(this).html())
  }), $('#advance-total').html(`${n} USD`)
}

function showBonusTotals() {
  var total = 0;
  $('.bonus-amount').each(function () {
    total += Number($(this).html());
  });
  $('#bonus-total').html(`${total.toFixed(2)} USD`);
}

$(document).on('click', '.add-advanced-payment', showAdvanceTotals);
$(document).on('click', '.remove-advance-payment', showAdvanceTotals);

$(document).on('click', 'add-bonus-payment', showBonusTotals);
$(document).on('click', 'remove-bonus-payment', showBonusTotals);
