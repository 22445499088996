import consumer from "../../channels/consumer"

let progressAmountTimeTrackingChannel;

$(document).on('turbolinks:load', function () {
  $('#payment-month').on('change', function() {
    const selectedMonth = $(this).val();
    $('#generate-time-tracking-amount-btn').attr('data-params', `payment_month=${selectedMonth}`);
  });

  $('.generate-time-tracking').click(function () {
    $('#progress-bar').css('width', '0%').text('');
    subscribeToProgressChannel();
    $('#loader').show();
  });

  $('.collapse').on('show.bs.collapse', function() {
    $(`[data-target="#${this.id}"], [href="#${this.id}"]`).find('.collapse-icon').toggleClass('fa-angle-down fa-angle-up');
  }).on('hide.bs.collapse', function() {
    $(`[data-target="#${this.id}"], [href="#${this.id}"]`).find('.collapse-icon').toggleClass('fa-angle-up fa-angle-down');
  });

  $('.btn-with-loader').click(function () {
    const $this = $(this);
    $this.hide();
    $this.next('#loader').show();
  });

  $(document).on('turbolinks:before-visit', function() {
    unsubscribeFromProgressChannel();
  });

  $('#admin-expenses-item-category').change(function() {
    const selectedCategory = $(this).val();
    let placeholderText = "Amount in UAH";

    if (selectedCategory === "working_capital_admin" || selectedCategory === "donates") {
      placeholderText = "Amount in USD";
    }

    $('#admin-expenses-item-value').attr('placeholder', placeholderText);
  });
});

function unsubscribeFromProgressChannel() {
  if (progressAmountTimeTrackingChannel) {
    consumer.subscriptions.remove(progressAmountTimeTrackingChannel);
    progressAmountTimeTrackingChannel = null;
  }
}

function subscribeToProgressChannel() {
  progressAmountTimeTrackingChannel = consumer.subscriptions.create("ProgressAmountTimeTrackingChannel", {
    received(data) {
      const total = data.processed + data.remaining;
      const percentage = Math.round((data.processed / total) * 100);
      $('#progress-bar').css('width', `${percentage}%`).text(`${percentage}%`);

      $('td').removeClass('processing-user');
      $(`td[data-user-id='${data.current_user_id}']`).addClass('processing-user');

      if (data.last_processed_user_id) {
        $(`td[data-user-id='${data.last_processed_user_id}']`).removeClass('processing-user').addClass('processed-user');
      }
    }
  });
}
