$(document).on('turbolinks:load', function () {
  $userShowPage = $('.user-show-page')

  $('#load_redmine').click(function(){
    if ( $userShowPage.length ) {
      userId = $userShowPage.attr('id').split('_')[1];
      $(this).hide()

      $('#redmine-hours-container').show()
      
      $.get(`/redmine_hours?id=${userId}`, function (data) {
        $('#redmine-hours-container').html(data)
      });
    }
  })

  function getRedmineUserId() {
    const email = $('#user-email').val().trim();
    const redmineUserIdInput = $('#redmine-user-id');

    setTimeout(function() {
      $.ajax({
        url: '/redmine_user_id',
        dataType: 'json',
        data: { email: email },
        success: function(data) {
          redmineUserIdInput.val(data.redmine_user_id);
          redmineUserIdInput.removeClass('is-invalid').addClass('is-valid');
        },
        error: function() {
          redmineUserIdInput.removeClass('is-valid').addClass('is-invalid');
        }
      });
    }, 1500);
  }

  $(document).on('input', '#user-email', getRedmineUserId);
})
