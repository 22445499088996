$(document).on('turbolinks:load', () => {
  $(function () {
      $('[data-toggle="tooltip"]').tooltip()
  });

  $("#selectAll").select_all();

  // Smooth scrolling to top
  $(document).on('click', 'a.scroll-to-top', event => {
    $('body,html').animate({scrollTop: 0}, 800);
    event.preventDefault();
  });

  // Toastr notifications
  toastr.remove();
  $('.toastr').each((index, elem) => {
    let type = $(elem).data('type');
    let msg = $(elem).data('msg');

    toastr[type](msg, 'Info');
  });
});

$(document).on('turbolinks:before-cache', () => {
  $('[data-toggle="tooltip"]').tooltip('dispose');
});
