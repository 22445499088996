$(document).on('turbolinks:load', () => {
  $('#batch_pay_invoices').click(function(){
    console.log('here')
    checked_invoices =  $('.xls-check:checked')
    invoices_ids = checked_invoices.toArray().map(e => e.value)
    url = '/invoices/batch_paid'

    $.ajax({
      type: "POST",
      url: url,
      data: {'invoices_ids': invoices_ids},
      success: function () {
        window.location.reload()
      }
    });
  });

  $('.advance-checkbox').change(function() {
    const userId = $(this).data('user-id');
    const invoiceField = $(`#price_for_user_${userId}`);
    let total = parseFloat(invoiceField.val());
    const advancePayments = parseFloat($(this).data('amount')) || 0;

    if ($(this).is(':checked')) {
      total += advancePayments;
    } else {
      total -= advancePayments;
    }

    invoiceField.val(total.toFixed(2));
  });

  $('.bonus-payment-checkbox').change(function () {
    const userId = $(this).data('user-id');
    const invoiceField = $(`#price_for_user_${userId}`);
    let total = parseFloat(invoiceField.val());
    const bonusPayment = parseFloat(invoiceField.data('bonus-payments'))

    if ($(this).is(':checked')) {
      total += bonusPayment;
    } else {
      total -= bonusPayment;
    }

    invoiceField.val(total.toFixed(2));
  });
});
