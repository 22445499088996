$(document).on('turbolinks:load', function () {
  const year = $('.charts').data('year');

  const initPieChart = () => {
    const pieChartElement = document.getElementById('pieChart');
    if (!pieChartElement) return;

    const invoiceData = JSON.parse(pieChartElement.getAttribute('data-invoice-data'));
    const formattedPieData = Object.keys(invoiceData).map(key => ({ name: key, value: invoiceData[key] }));

    const pieChart = echarts.init(pieChartElement);
    const pieOption = {
      title: {
        text: 'Projects ' + year,
        subtext: "Revenue",
        left: 'center',
        textStyle: {
          fontSize: 30
        }
      },
      tooltip: {
        trigger: 'item',
        formatter: (params) => {
          return `${params.marker} ${params.name}: $${parseFloat(params.value).toLocaleString()}`;
        }
      },
      legend: {
        orient: 'horizontal',
        bottom: 0
      },
      series: [
        {
          type: 'pie',
          radius: '55%',
          label: {formatter: '{b}: {d}%'},
          data: formattedPieData,
          emphasis: {
            itemStyle: {
              shadowBlur: 10,
              shadowOffsetX: 0,
              shadowColor: 'rgba(0, 0, 0, 0.5)'
            }
          }
        }
      ]
    };

    pieChart.setOption(pieOption);
  };

  const initBarChart = () => {
    const barChartElement = document.getElementById('barChart');
    if (!barChartElement) return;

    const monthlyGrossProfitData = JSON.parse(barChartElement.getAttribute('data-monthly-gross-profit'));
    const categories = Object.keys(monthlyGrossProfitData);
    const data = Object.values(monthlyGrossProfitData);

    const barChart = echarts.init(barChartElement);
    const barOption = {
      title: {
        text: 'Monthly Gross Profit ' + year,
        left: 'center',
        textStyle: {
          fontSize: 30
        }
      },
      tooltip: {
        trigger: 'axis',
        axisPointer: {
          type: 'shadow'
        },
        formatter: (params) => {
          let result = params[0].axisValueLabel + '<br/>';
          params.forEach(item => {
            result += '$' + parseFloat(item.data).toLocaleString();
          });
          return result;
        }
      },
      xAxis: {
        type: 'category',
        data: categories
      },
      yAxis: {
        type: 'value',
        axisLabel: {
          formatter: (value) => '$' + value.toLocaleString()
        }
      },
      series: [{
        data: data,
        type: 'bar'
      }]
    };

    barChart.setOption(barOption);
  };

  const initStackedBarChart = () => {
    const stackedBarChartElement = document.getElementById('stackedBarChart');
    if (!stackedBarChartElement) return;

    const monthlyExpensesData = JSON.parse(stackedBarChartElement.getAttribute('data-monthly-expenses'));
    const expensesColors = JSON.parse(stackedBarChartElement.getAttribute('data-expenses-colors'));

    const months = Object.keys(monthlyExpensesData[0].data);

    const seriesData = monthlyExpensesData.map((category, index) => ({
      name: category.name,
      type: 'bar',
      stack: 'total',
      data: months.map(month => category.data[month] || 0),
      itemStyle: {
        color: expensesColors[index % expensesColors.length]
      }
    }));

    const stackedBarChart = echarts.init(stackedBarChartElement);
    const stackedBarOption = {
      title: {
        text: 'Monthly Admin Expenses ' + year,
        left: 'center',
        textStyle: {
          fontSize: 30
        }
      },
      tooltip: {
        trigger: 'axis',
        axisPointer: { type: 'shadow' },
        formatter: (params) => {
          let result = params[0].axisValueLabel + '<br/>';
          params.forEach(item => {
            const formattedValue = parseFloat(item.value) === 0
              ? parseInt(item.value, 10).toLocaleString()
              : parseFloat(item.value).toFixed(2).toLocaleString();

            result += item.marker + ' ' + item.seriesName + ': $' + formattedValue + '<br/>';
          });
          return result;
        }
      },
      legend: {
        data: monthlyExpensesData.map(category => category.name),
        orient: 'vertical',
        left: 'right'
      },
      xAxis: {
        type: 'category',
        data: months
      },
      yAxis: {
        type: 'value',
        axisLabel: {
          formatter: (value) => '$' + value.toLocaleString()
        }
      },
      series: seriesData
    };

    stackedBarChart.setOption(stackedBarOption);
  };

  const initCombinedChart = () => {
    const combinedChartElement = document.getElementById('combinedChart');
    if (!combinedChartElement) return;

    const combinedData = JSON.parse(combinedChartElement.getAttribute('data-combined-financials'));
    const invoiceTotalsData = JSON.parse(combinedChartElement.getAttribute('data-invoice-totals'));
    const months = Object.keys(combinedData);
    const costOfSalesData = months.map(month => combinedData[month].cost_of_sales);
    const ebitdaData = months.map(month => combinedData[month].ebitda);
    const administrative_expensesData = months.map(month => combinedData[month].administrative_expenses);

    const stackedSeriesData = invoiceTotalsData.map(item => ({
      name: item.name,
      type: 'bar',
      stack: 'stacked',
      data: months.map(month => item.data[month] || 0)
    }));

    const combinedChart = echarts.init(combinedChartElement);
    const combinedOption = {
      title: {
        text: 'Monthly Combined Financial ' + year,
        left: 'center',
        textStyle: {
          fontSize: 30
        }
      },
      tooltip: {
        trigger: 'axis',
        axisPointer: { type: 'shadow' },
        formatter: (params) => {
          let result = params[0].axisValueLabel + '<br/>';
          params.forEach(item => {
            console.log(item.value);
            const value = item.value !== undefined ? (item.value === '0.0' ? '0' : item.value.toLocaleString()) : '0';
            result += `${item.marker} ${item.seriesName}: $${value}<br/>`;
          });
          return result;
        }
      },
      grid: {
        bottom: '35%'
      },
      legend: {
        data: stackedSeriesData.map(item => item.name).concat(['Cost of Sales', 'EBITDA', 'Administrative Expenses']),
        orient: 'horizontal',
        bottom: 0
      },
      xAxis: {
        type: 'category',
        data: months
      },
      yAxis: {
        type: 'value',
        axisLabel: {
          formatter: (value) => '$' + value.toLocaleString()
        }
      },
      series: [
        ...stackedSeriesData,
        {
          name: 'Cost of Sales',
          type: 'line',
          smooth: true,
          data: costOfSalesData
        },
        {
          name: 'EBITDA',
          type: 'line',
          smooth: true,
          data: ebitdaData
        },
        {
          name: 'Administrative Expenses',
          type: 'line',
          smooth: true,
          data: administrative_expensesData
        }
      ]
    };

    combinedChart.setOption(combinedOption);
  };

  initPieChart();
  initBarChart();
  initStackedBarChart();
  initCombinedChart();
});
