function setRates(e, insertedItem) {
  insertedItem.find('.rate-currency').val($('#project-currency').val());
}

function updateRates() {
  $('.rate-currency').val($(this).val());
}

$(document).on('cocoon:after-insert', '#rates', setRates);
$(document).on('change', '#project-currency', updateRates);
