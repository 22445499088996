$(document).on('turbolinks:load', function () {
  const selectYearsForm = $(".select-years-form");
  const dateYear = $("#date_year");

  const handleSubmit = () => selectYearsForm.submit();
  const handlePrevYear = () => dateYear.val(parseInt(dateYear.val()) - 1) && handleSubmit();
  const handleNextYear = () => dateYear.val(parseInt(dateYear.val()) + 1) && handleSubmit();

  $(document).on('change', '#date_year', handleSubmit);
  $(document).on('click', '.prev-year', handlePrevYear);
  $(document).on('click', '.next-year', handleNextYear);
});
